export default function () {
  // Function to get geolocation data
  function getGeolocation() {
    var apiKey = 'c43f1d6c2b9f4ec29478514c74a5eeaf'; // Your IPGeolocation API key
    var apiUrl = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + apiKey;
    $.get(apiUrl, function (response) {
      if (response && (response.country_code2 === 'AE' || response.country_code2 === 'SA')) {
        $('#qlwapp').show();
      } else {
        $('#qlwapp').hide();
      }
    });
  }

  // Hide the chat widget initially
  $('#qlwapp').hide();

  // Get geolocation data
  getGeolocation();
}