function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export default function () {
  function updateTotal() {
    // Find the table within the specific class and extract the value from the <td>
    var tableValue = $('.wpforms-field-html.wpforms-conditional-field.wpforms-conditional-show table tbody tr td').text().trim();
    console.log('Table Value:', tableValue); // Debug log to check extracted value

    // Check if tableValue is not empty and can be parsed
    if (tableValue) {
      // Parse the value to a float and convert the currency if necessary
      var parsedValue = parseFloat(tableValue.replace('£', '').replace(',', ''));
      var convertedValue = parsedValue * 1.20; // Add 20% tax
      console.log('Converted Value with Tax:', convertedValue); // Debug log to check parsed value with tax

      // Check if a valid coupon is applied
      var couponWrapper = $('.wpforms-field-payment-coupon-wrapper');
      var isCouponApplied = couponWrapper.hasClass('wpforms-field-payment-coupon-wrapper-applied');
      console.log('Is Coupon Applied:', isCouponApplied); // Debug log to check coupon application status

      var discount = isCouponApplied ? 0.10 : 0; // Apply 10% discount if coupon is valid
      var finalValue = convertedValue * (1 - discount);
      console.log('Final Value After Discount:', finalValue); // Debug log to check final calculated value

      // Update the input field with the ID wpforms-3322-field_91
      var inputField = $('#wpforms-3322-field_91');
      inputField.val('£' + finalValue.toFixed(2));
      console.log('Updated Input Field Value:', inputField.val()); // Debug log for input field

      // Update the hidden input field with the calculated value
      var hiddenInput = $('#wpforms-3322-field_91_hidden');
      hiddenInput.val('£' + finalValue.toFixed(2));
      console.log('Updated Hidden Input Value:', hiddenInput.val()); // Debug log for hidden input field

      // Update the span with the class wpforms-price
      var priceSpan = $('.wpforms-price');
      priceSpan.text('£' + finalValue.toFixed(2));
      console.log('Updated Span Value:', priceSpan.text()); // Debug log for span

      // Ensure the input field remains readonly
      inputField.prop('readonly', true);
    }
  }

  // Initial setup
  function initializePrice() {
    // Set initial value of the span with the class wpforms-price
    var priceSpan = $('.wpforms-price');
    priceSpan.text('£0.00');
    console.log('Initialized Span Value:', priceSpan.text()); // Debug log for span initialization

    // Ensure the input field remains readonly
    var inputField = $('#wpforms-3322-field_91');
    inputField.prop('readonly', true);

    // Create and append the hidden input field
    if ($('#wpforms-3322-field_91_hidden').length === 0) {
      $('<input>').attr({
        type: 'hidden',
        id: 'wpforms-3322-field_91_hidden',
        name: 'wpforms[fields][91_hidden]',
        value: '£0.00'
      }).appendTo('form');
      console.log('Hidden Input Field Created'); // Debug log for hidden field creation
    }
  }

  // Observe changes in the specific HTML element
  function observeTableChanges() {
    var targetNode = document.querySelector('.wpforms-field-html.wpforms-conditional-field.wpforms-conditional-show');
    var config = {
      childList: true,
      subtree: true,
      characterData: true
    };
    var callback = function callback(mutationsList) {
      var _iterator = _createForOfIteratorHelper(mutationsList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var mutation = _step.value;
          if (mutation.type === 'childList' || mutation.type === 'characterData') {
            console.log('Table Mutation Observed'); // Debug log for mutation observer
            updateTotal();
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    };
    var observer = new MutationObserver(callback);
    if (targetNode) {
      observer.observe(targetNode, config);
      console.log('Observer Set Up for Table Changes'); // Debug log for observer setup
    }
  }

  // Observe dropdown changes
  function observeDropdownChanges() {
    $('#wpforms-3322-field_17, #wpforms-3322-field_19, #wpforms-3322-field_18, #wpforms-3322-field_20').change(function () {
      console.log('Dropdown Value Changed'); // Debug log for dropdown change
      setTimeout(updateTotal, 0); // Ensure the update happens after the DOM changes
    });
  }

  // Observe coupon application
  function observeCouponApplication() {
    var couponWrapper = document.querySelector('.wpforms-field-payment-coupon-wrapper');
    if (couponWrapper) {
      var config = {
        attributes: true,
        attributeFilter: ['class']
      };
      var callback = function callback(mutationsList) {
        var _iterator2 = _createForOfIteratorHelper(mutationsList),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var mutation = _step2.value;
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
              var isCouponApplied = $(couponWrapper).hasClass('wpforms-field-payment-coupon-wrapper-applied');
              console.log('Coupon Status Changed:', isCouponApplied); // Debug log for coupon status change
              updateTotal(); // Update total when coupon is applied
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      };
      var observer = new MutationObserver(callback);
      observer.observe(couponWrapper, config);
      console.log('Observer Set Up for Coupon Changes'); // Debug log for observer setup
    }
  }

  // Initialize price and observers
  initializePrice();
  observeTableChanges();
  observeDropdownChanges();
  observeCouponApplication();
}