/**
 * Scripts to control styling and position of navbar.
 */
export default function () {
  var prevDistFromTop = $(document).scrollTop();

  /**
   * Sets styles on navbar to show navbar at top of page or on upscroll, and hide when scrolling down.
   */
  function setNavbarUpscrollStyling() {
    // do nothing if menu is open
    if ($('.nav').hasClass('nav--menu-open')) {
      return;
    }
    var distFromTop = $(document).scrollTop();
    var scrollPixels = distFromTop - prevDistFromTop;
    var navHeight = parseInt($('.nav').height());
    var upscrollActivateDist = navHeight >= 60 ? 100 : navHeight;
    prevDistFromTop = distFromTop;
    if (distFromTop > upscrollActivateDist) {
      // scroll is far enough from top of page
      $('.nav').removeClass('nav--no-upscroll');
      if (scrollPixels > 0) {
        // scroll down
        $('.nav').removeClass('nav--upscroll-active');
        $('.nav').addClass('nav--upscroll-inactive');
      } else {
        // scroll up
        $('.nav').removeClass('nav--upscroll-inactive');
        $('.nav').addClass('nav--upscroll-active');
      }
    } else {
      $('.nav').addClass('nav--no-upscroll');
      $('.nav').removeClass('nav--upscroll-active');
      $('.nav').removeClass('nav--upscroll-inactive');
    }
  }

  /**
   * Set navbar position so the alert banner is visible when scrolled to top of page.
   */
  function leaveRoomForAlert() {
    if ($('.alert').hasClass('shown')) {
      var dtop = $(document).scrollTop();
      var alertHeight = $('.alert').outerHeight();
      if (dtop > alertHeight) {
        $('.nav').css({
          'top': 0
        });
        $('.nav').removeClass('alert-visible');
      } else {
        $('.nav').css({
          'top': alertHeight - dtop + 'px'
        });
        $('.nav').addClass('alert-visible');
      }
    } else {
      $('.nav').css({
        'top': 0
      });
    }
  }

  // when the page has a hero with an image
  // - display a gradient background instead of solid color
  // - add padding to body
  if ($('.body-content section:first-of-type').hasClass('hero-with-image')) {
    $('.nav').addClass('nav--image-beneath');
    $('.body-content').addClass('has-hero-image');
  } else {
    $('.nav').addClass('nav--no-image-beneath');
    $('.body-content').addClass('no-hero-image');
  }

  // handle hamburger button click
  $('.nav__btn-hamburger').click(function () {
    // for animation
    $(this).toggleClass('is-active');
    if ($('.nav__menu').hasClass('nav__menu--open')) {
      $('body').removeClass('menu-open');
      $('.nav').removeClass('nav--menu-open');
      $('.nav__menu').removeClass('nav__menu--open');
      $(this).removeClass('nav__btn-hamburger--active');
    } else {
      $('body').addClass('menu-open');
      $('.nav').addClass('nav--menu-open');
      $('.nav__menu').addClass('nav__menu--open');
      $(this).addClass('nav__btn-hamburger--active');
    }
  });

  // handle search open and close button click
  $('.nav__btn-search-open, .nav__btn-search-open-md').click(function () {
    if (!$('.nav__search').hasClass('nav__search--open')) {
      $('.nav__search').addClass('nav__search--open');

      // On focus keyboard search hides
      if (navigator.userAgent.match(/iPhone/i)) {
        var dtop = $(document).scrollTop();
        var alertHeight = $('.alert').outerHeight();
        $('.nav .nav__search-wrapper .nav__search .nav__search-form .nav__search-input').on('focusin', function (e) {
          $('.alert.shown').css({
            visibility: 'hidden'
          });
          $('.nav').css({
            'top': 0
          });
        });
        $('.nav .nav__search-wrapper .nav__search .nav__search-form .nav__search-input').on('focusout', function (e) {
          if (dtop > alertHeight) {
            $('.nav').css({
              'top': 0
            });
          } else {
            $('.nav').css({
              'top': alertHeight + 'px'
            });
            $('.alert.shown').css({
              visibility: 'visible'
            });
          }
        });
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();
          if (scroll >= 50) {
            $('.alert.shown').css({
              visibility: 'visible'
            });
          }
        });
      }
    }
  });
  $('.nav__btn-search-close').click(function () {
    if ($('.nav__search').hasClass('nav__search--open')) {
      $('.nav__search').removeClass('nav__search--open');
      $('.nav__search-input').val('');
    }
  });

  // search submission
  $('.nav__btn-search-submit').click(function () {
    var input = $(this).parent().find('.nav__search-input');
    var url = input.attr('data-url');
    window.location.href = url + '?s=' + encodeURIComponent(input.val());
  });

  // handle menu link click
  // - display leaf and wait some time before directing to new page
  // $('.nav__menu a').click(function(e) {
  //     e.preventDefault();

  //     const anchor = $(this);
  //     const url = anchor.attr('href');

  //     anchor.parent('li').toggleClass('clicked');

  //     setTimeout(function() {
  //         location.href = url;
  //     }, 1000);
  // });

  $('.parent-angle-down-md').click(function (event) {
    event.preventDefault(); // Prevent the default behavior of the anchor element

    // Toggle the 'clicked' class on the parent 'li'
    $(this).closest('li').toggleClass('clicked');
  });

  // set initial navigation state according to alert
  leaveRoomForAlert();

  // track page scroll
  $(window).scroll(function () {
    // set upscroll state on navbar
    setNavbarUpscrollStyling();

    // make room for alert banner when at top of screen
    leaveRoomForAlert();
  });

  // adjust nav when alert is shown or hidden
  $(document).on('alert-changed', function () {
    leaveRoomForAlert();
  });
  if ($(window).width() < 768) {
    $(".nav__menu--js ul li .icon-js").on("click", function () {
      $(this).parent(".has_dropdown").children(".nav__sub_menu--js")
      // .css({ "padding-left": "15px" })
      .stop().slideToggle();
      $(this).parent(".has_dropdown").children("svg").find(".fa-angle-right").stop().toggleClass("fa-rotate-90");
    });
  }
  $('.has_dropdown .icon--plus-js').click(function (event) {
    event.preventDefault(); // Prevent the default behavior of the anchor element

    // Toggle the visibility of the corresponding sub-menu
    $(this).closest('.has_dropdown').find('.nav__sub_menu--js').slideToggle();

    // Toggle the 'clicked' class on the parent 'li'
    $(this).closest('.has_dropdown').toggleClass('clicked');
  });
  $('.nav__sub_menu--children-m-item--js').on("click", function () {
    $(this).find('i').toggleClass('icon--plus--active');
  });
  $('.nav__sub_menu--children-m-item--js').on("click", function () {
    $(this).children(".nav__sub_menu--subchildren-m--js").stop().slideToggle();
  });
  var hoveredAnnouncement = null;
  function announcementTicker() {
    $(".announcements").filter(function (item) {
      return !$(this).is(hoveredAnnouncement);
    }).each(function () {
      $(this).find("p:first").slideUp(function () {
        var announcement = $(this).closest(".announcements");
        $(this).appendTo(announcement).slideDown();
      });
    });
  }
  setInterval(announcementTicker, 5000);
  $(function () {
    $(".announcements").hover(function () {
      hoveredAnnouncement = $(this);
    }, function () {
      hoveredAnnouncement = null;
    });
  });
}