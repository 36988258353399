export default function () {
  var accordionBtns = document.querySelectorAll(".faq__card-js");
  accordionBtns.forEach(function (accordion) {
    accordion.onclick = function () {
      this.classList.toggle("is-open");
      var content = this.nextElementSibling;
      console.log(content);
      if (content.style.maxHeight) {
        //this is if the accordion is open
        content.style.maxHeight = null;
      } else {
        //if the accordion is currently closed
        content.style.maxHeight = content.scrollHeight + "px";
        console.log(content.style.maxHeight);
      }
    };
  });

  // Hide the form initially
  $('.event-form, .competition-form').hide();
  $('.even-from-show, .competition-from-show').click(function () {
    // for animation
    $(this).toggleClass('is-active');
    $('.event-form, .competition-form').slideToggle();
    // $('.main-event').toggleClass('inactive'); 

    // Scroll to the form
    var formOffset = $('.event-form, .competition-form').offset().top;
    $('html, body').animate({
      scrollTop: formOffset
    }, 800);
  });
  $('.show-more-content').hide();
  $('.show-more').click(function () {
    $(this).closest('.threat-alerts-block__wrapper').find('.show-more-content').slideDown();
    $(this).closest('.threat-alerts-block__wrapper').find('.show-less').show();
    $(this).hide();
  });
  $('.show-less').click(function () {
    $(this).closest('.threat-alerts-block__wrapper').find('.show-more-content').slideUp();
    $(this).closest('.threat-alerts-block__wrapper').find('.show-more').show();
    $(this).hide();
  });
}