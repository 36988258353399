/**
 * Play hero videos according to screen size, avoid multiple videos playing together.
 */
export default function () {
  if ($('.herovideo').length === 0) {
    return;
  }

  /**
   * Play mobile or desktop video according to viewport width.
   */
  function playCorrectVideo() {
    var screenWidth = $(window).width();
    if (screenWidth >= 1024) {
      $('.herovideo--md')[0].play();
      $('.herovideo--mobile')[0].pause();
    } else {
      $('.herovideo--md')[0].pause();
      $('.herovideo--mobile')[0].play();
    }
  }
  playCorrectVideo();
  $(window).resize(function () {
    playCorrectVideo();
  });
}