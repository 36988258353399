/**
 * Submit functionality for Search Form.
 */
export default function () {
  if ($('.search-form').length === 0) {
    return;
  }

  /**
   * Redirect to the appropriate search page with search term prefilled.
   * @param {string} searchTerm Words to be searched
   * @param {string} searchUrl URL of search page with search parameter suffixed
   */
  function submitSearch(searchTerm, searchUrl) {
    window.location.href = searchUrl + encodeURI(searchTerm);
  }
  $('.search-form').each(function () {
    var searchUrl = $(this).attr('data-search-url');

    // catch enter key presses
    $('input', this).on('keyup', function (ev) {
      if (ev.keyCode === 13) {
        var searchTerm = $(this).val();
        submitSearch(searchTerm, searchUrl);
      }
    });

    // catch button clicks
    $('button', this).click(function () {
      var searchTerm = $(this).parents('.search-form').find('input').val();
      submitSearch(searchTerm, searchUrl);
    });
  });
}