/**
 * Set all cards to equal height.
 */
export default function () {
  /**
   * Measure default card heights and increase all to match tallest card in each block.
   */
  function standardizeCardHeights() {
    $('.featured-content, .service-card, .featured-location, .service-inner-card, .feature-knowledge-base').each(function () {
      // remove existing fixed height before measuring
      $('.featured-content__text-wrapper, .service-card__cards--content--js, .featured-location__text-wrapper--js, .service-inner-card__cards--content--js, .feature-knowledge-base__cards--content--js', this).each(function () {
        $(this).css({
          'height': ''
        });
      });

      // find largest card height
      var largestHeight = 0;
      $('.featured-content__text-wrapper, .service-card__cards--content--js, .featured-location__text-wrapper--js, .service-inner-card__cards--content--js, .feature-knowledge-base__cards--content--js', this).each(function () {
        var thisCardHeight = $(this).outerHeight();
        if (thisCardHeight > largestHeight) {
          largestHeight = thisCardHeight;
        }
      });

      // Rounding up the value
      if (!isNaN(largestHeight)) {
        largestHeight = Math.ceil(largestHeight);
      }

      // set fixed height for all cards in block (including 2px border) + buffer
      var standardizedHeight = largestHeight + 3 + 'px';
      $('.featured-content__text-wrapper, .service-card__cards--content--js, .featured-location__text-wrapper--js, .service-inner-card__cards--content--js, .feature-knowledge-base__cards--content--js', this).each(function () {
        $(this).css({
          'height': standardizedHeight
        });
      });
    });
  }

  // set heights on page load
  standardizeCardHeights();
  setTimeout(function () {
    standardizeCardHeights();
  }, 1000);

  // set heights on page resize
  $(window).resize(function () {
    standardizeCardHeights();
  });
}