/**
 * Hero with Text on Image
 *
 * Scripts to control gallery-mode image rotation.
 */
export default function () {
  if ($('.hero-text-image').length === 0) {
    return;
  }

  /**
   * Displays next image in hero gallery.
   * @param {int} interval Number of seconds to display each image
   */
  function displayNextImage(interval) {
    var currentIndex = parseInt($('.hero-text-image').attr('data-img-current'));
    var totalImages = parseInt($('.hero-text-image').attr('data-img-total'));
    var nextIndex = currentIndex + 1;
    if (nextIndex == totalImages) {
      nextIndex = 0;
    }
    $('.hero-text-image img[data-img-index="' + currentIndex + '"]').removeClass('active');
    $('.hero-text-image img[data-img-index="' + nextIndex + '"]').addClass('active');
    var newCaption = $('.hero-text-image__data span[data-img-index="' + nextIndex + '"]').html();
    $('.hero-text-image__caption').html(newCaption);
    $('.hero-text-image').attr('data-img-current', nextIndex);
    $('.hero-text-image__caption').attr('data-img-index', nextIndex);
    setTimeout(function () {
      displayNextImage(interval);
    }, interval * 1000);
  }

  // cycle between images when more than one is available
  if ($('.hero-text-image__backdrop--md > img').length > 1) {
    var interval = 7; // seconds

    setTimeout(function () {
      displayNextImage(interval);
    }, interval * 1000);
  }
}