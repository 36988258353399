/* eslint-env browser */
'use strict';

import flatpickr from 'flatpickr';
import Cookies from 'js-cookie';
import aos from './vendor/aos';
import { CountUp } from './vendor/countUp.min.js';
import nav from './modules/nav';
import heroTextImage from './modules/heroTextImage';
import featuredContent from './modules/featuredContent';
import featureSlider from './modules/featureSlider';
import anchorLinks from './modules/anchorLinks';
import accordion from './modules/accordion';
import chatBot from './modules/chatBot';
import search from './modules/searchForm';
import carousel from './modules/carousel';
import matrix from './modules/matrix';
// import alerts from './modules/alerts';
import geolocation from './modules/geolocation';
import formCalculation from './modules/formCalculation';
import heroVideo from './modules/heroVideo';
(function () {
  aos.init();
  nav();
  heroTextImage();
  featuredContent();
  featureSlider();
  anchorLinks();
  accordion();
  chatBot();
  search();
  carousel();
  matrix();
  // alerts();
  geolocation();
  formCalculation();
  heroVideo();
})(jQuery);