/**
 * Apply scroll animations on anchor links.
 */
export default function () {
  $('.goaco-anchor-link').click(function (ev) {
    var targetId = $(this).attr('href').replace('#', '');
    var $targetDiv = $('[id="' + targetId + '"]');

    // Toggle 'active-div' class on the corresponding div
    $($(this).attr('href')).addClass('active-div').siblings().removeClass('active-div');

    // Other actions from your original code
    $('.goaco-anchor-link').removeClass('active');
    $(this).addClass('active');
    var top = $targetDiv.offset().top - 90; // offset 90 to account for navbar
    $('html, body').stop().animate({
      scrollTop: top
    }, 1000);
    ev.preventDefault();
  });
}