export default function () {
  new Swiper('.featured-slider--js', {
    spaceBetween: 30,
    effect: 'fade',
    loop: true,
    mousewheel: {
      invert: false
    },
    // autoHeight: true,
    pagination: {
      el: '.featured-slider__pagination--js',
      clickable: true
    },
    autoplay: {
      delay: 5000,
      // Set the delay in milliseconds (e.g., 5000 for 5 seconds)
      disableOnInteraction: false // Set to false to keep auto-rotation even if the user interacts with the slider
    }
  });

  var tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  function onYouTubeIframeAPIReady() {
    document.querySelectorAll('.ytplayer').forEach(function (item) {
      new YT.Player(item, {
        events: {
          'onReady': function onReady(event) {
            event.target.playVideo();
            event.target.mute();
          }
        }
      });
    });
  }
}